<!-- 模块说明 -->
<template>
  <div class="container">
    <div class="banner">
      <div class="banner-view fadeInLeft">
        <div class="view">
          <div class="title">灵活用工解决方案</div>
          <div class="henxin"></div>
          <div class="text">
            海纳明人致力于为企业提供专业而灵活的用工解决方案，有效应对企业季节性、临时性、周期性的发展变革挑战。<br />
            针对企业因新项目、员工短期替补、非核心业务员工流动性大、编制及薪资总额受限等原因带来的人才短缺问题，提供短、中、长期人员及业务外包一站式综合解决方案，帮助政企提升效能，减轻编制压力，降低用工成本，实现降本增效的目的。
          </div>
        </div>
      </div>
      <div class="decoration"></div>
    </div>
    <div class="modular modular-1">
      <div class="title">
        <div class="t1">服务项目</div>
        <div class="t2">Service Items</div>
        <div class="t3"></div>
      </div>
      <div class="content flex">
        <div v-for="(item, index) in projectList" :key="index" class="list">
          <div class="top">
            <el-image :src="item.img" fit="cover"></el-image>
          </div>
          <div class="bottom">
            <div class="name">{{ item.name }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modular modular-2">
      <div class="title">
        <div class="t1">解决方案</div>
        <div class="t2" style="color: #666666">
          覆盖从员工招聘、日常管理及劳动风险管理的HR全流程服务
        </div>
        <div class="t3"></div>
      </div>
      <div class="content flex">
        <div class="list">
          <img src="../../images/home/programme/yuangongzhaopin.png" />
          <div class="name">员工招聘</div>
          <div class="text">人才搜寻推荐</div>
          <div class="text">面试安排</div>
          <div class="text">待遇谈判、Offer发放</div>
        </div>
        <div class="list">
          <img src="../../images/home/programme/richangguanli.png" />
          <div class="name">日常管理</div>
          <div class="text">入离职及档案管理</div>
          <div class="text">薪酬福利及社保代缴</div>
          <div class="text">日常工作及考勤管理</div>
          <div class="text">绩效管理</div>
          <div class="text">在岗培训</div>
        </div>
        <div class="list">
          <img src="../../images/home/programme/laodongfengxianguanli.png" />
          <div class="name">劳动风险管理</div>
          <div class="text">员工替换</div>
          <div class="text">劳资纠纷</div>
          <div class="text">工伤申报</div>
        </div>
      </div>
    </div>
    <div class="modular modular-3">
      <div class="content flex">
        <div class="view view1">
          <div class="name">服务价值</div>
          <div class="text">Service Value</div>
          <div class="heng"></div>
        </div>
        <div class="view view2">
          <div class="name">满足组织弹性</div>
          <div class="text">
            短期用工随需而变，有效增强组织弹性，促进企业灵活化管理
          </div>
        </div>
        <div class="view view3">
          <div class="name">降低用工成本</div>
          <div class="text">
            没有了繁琐的HR全流程，减轻了财税及社保负担，降低了企业用工成本
          </div>
        </div>
        <div class="view view4">
          <div class="name">减轻编制压力</div>
          <div class="text">
            将适合的员工派遣到适合的岗位，将劳动雇佣关系转变为合作关系，解决了编制限制问题
          </div>
        </div>
        <div class="view view5">
          <div class="name">提升政企效能</div>
          <div class="text">
            帮助客户从繁琐的事务性工作中解脱出来，将工作聚焦在核心事务上，提高企业的整体运营效率
          </div>
        </div>
        <div class="view view6">
          <div class="name">减少用工风险</div>
          <div class="text">
            劳资纠纷、工伤处理等用工风险转移到人力资源机构，能避免/减低企业自身风险
          </div>
        </div>
      </div>
    </div>
    <div class="modular modular-4">
      <div class="title">
        <div class="t1">服务优势</div>
        <div class="t2">Service advantages</div>
        <div class="t3"></div>
      </div>
      <div class="content">
        <div class="view view1">
          <div class="name">服务网络广</div>
          <div class="tezt">全国范围内已建立20余家分支机构，覆盖50余座城市</div>
          <!-- <img src="../../images/home/programme/jiao.png" /> -->
        </div>
        <div class="view view2">
          <div class="name">全风险管控</div>
          <div class="tezt">
            从员工入职前风险评估、在职风险预警管控、劳动争议协调处理等进行全
            方位管控，降低企业用工风险
          </div>
          <!-- <img src="../../images/home/programme/jiao.png" /> -->
          <img class="tu" src="../../images/home/programme/3Dtu.png" />
        </div>
        <div class="view view3">
          <div class="name">全流程服务</div>
          <div class="tezt">
            覆盖从员工招聘、日常管理及劳动风险管理的HR全流程服务
          </div>
          <!-- <img src="../../images/home/programme/jiao.png" /> -->
        </div>
        <div class="view view4">
          <div class="name">多场景覆盖</div>
          <div class="tezt">
            服务涉及外包和派遣，覆盖行业领域广泛，可满足各类别企业、多业务场景的服务需求
          </div>
          <!-- <img src="../../images/home/programme/jiao.png" /> -->
        </div>
        <div class="view view5">
          <div class="name">多渠道交付</div>
          <div class="tezt">
            渠道包括自身渠道（上职家）、网络、乡镇劳务经纪人、第三方平台等多渠道管理，保证交付
          </div>
          <!-- <img src="../../images/home/programme/jiao.png" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "灵活用工|企业用工新方案-海恒科技集团",
  },
  components: {},
  data() {
    return {
      projectList: [
        {
          name: "岗位外包",
          img: require("../../images/home/programme/gangweiwaibao.png"),
          text: "岗位外包服务可以为政企提供涵盖专业类、通用类、技能类等各类岗位的外包服务综合解决方案。",
        },
        {
          name: "业务外包",
          img: require("../../images/home/programme/yewuwaibao.png"),
          text: "业务外包服务可以为政企提供生产外包、财务外包、呼叫中心外包等各类业务外包，我们调配资源完成任务指标，实现资源合理利用，实现降本增效。",
        },
        {
          name: "劳务派遣",
          img: require("../../images/home/programme/laowupaiqian.png"),
          text: "劳务派遣服务为政企提供包括招聘服务（可选）、劳动合同、薪酬社保代缴、劳动争议等服务，企业可实现自主灵活用工，更专注于核心业务的发展，提升核心竞争力。",
        },
      ],
    };
  },
  watch: {},
  created() {},
  methods: {},
  computed: {},
};
</script>
<style lang="less" scoped>
.modular-4 {
  .content {
    .view4 {
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
    .view2 {
      .tu {
        width: 244px !important;
        height: 223px !important;
        top: -41px !important;
        right: 20px !important;
      }
      .tezt {
        width: 512px !important;
      }
      width: 800px !important;
      border-left: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
    }
    .view1 {
      border-bottom: 1px solid #dddddd;
    }
    .view:hover {
      background: #166dba !important;
      transition: all 1s;
      .name,
      .tezt {
        color: white !important;
        transition: all 1s;
      }
    }
    .view {
      img {
        width: 12px;
        height: 12px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .tezt {
        color: #666666;
        font-size: 16px;
        margin-left: 29px;
        width: 310px;
      }
      .name {
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        margin: 60px 29px 15px;
      }
      width: 399px;
      height: 200px;
      background: white;
      position: relative;
    }
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
  }
  background: #f3f3f3;
  margin-bottom: 110px;
}
.modular-3 {
  .content {
    .view:hover {
      margin-top: -15px;
      transition: all 1s;
    }
    .view6 {
      width: 282px;
      background: url(../../images/home/programme/jianshao.png) no-repeat !important;
      background-size: cover;
      .text {
        width: 210px;
      }
    }
    .view5 {
      width: 282px;
      background: url(../../images/home/programme/tisheng.png) no-repeat !important;
      background-size: cover;
      .text {
        width: 210px;
      }
    }
    .view4 {
      width: 588px;
      background: url(../../images/home/programme/jianqing.png) no-repeat !important;
      background-size: cover;
      .text {
        width: 336px;
      }
    }
    .view3 {
      width: 588px;
      background: url(../../images/home/programme/jiangdi.png) no-repeat !important;
      background-size: cover;
      .text {
        width: 336px;
      }
    }
    .view2 {
      width: 282px;
      background: url(../../images/home/programme/manzu.png) no-repeat !important;
      background-size: cover;
      .text {
        width: 210px;
      }
    }
    .view1 {
      .heng {
        width: 30px;
        height: 4px;
        background: white;
        margin-left: 77px;
      }
      .text {
        font-size: 16px !important;
        margin: 5px 77px 12px !important;
      }
      .name {
        font-size: 28px !important;
        font-weight: bold;
        margin: 40px 77px 0 !important;
      }
      width: 282px;
    }
    .view {
      .name {
        font-size: 20px;
        margin: 48px 23px 15px;
      }
      .text {
        font-size: 14px;
        margin-left: 23px;
        line-height: 20px;
      }
      height: 170px;
      color: white;
      overflow: hidden;
      margin-bottom: 28px;
      background: #166dba;
    }
    flex-wrap: wrap;
    margin: 30px auto 10px;
  }
}
.modular-2 {
  .list:hover {
    img {
      animation: flip 1000ms both;
    }
  }
  @keyframes flip {
    from {
      transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
      animation-timing-function: ease-out;
    }

    40% {
      transform: perspective(400px) translate3d(0, 0, 150px)
        rotate3d(0, 1, 0, -190deg);
      animation-timing-function: ease-out;
    }

    50% {
      transform: perspective(400px) translate3d(0, 0, 150px)
        rotate3d(0, 1, 0, -170deg);
      animation-timing-function: ease-in;
    }

    80% {
      transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
      animation-timing-function: ease-in;
    }

    to {
      transform: perspective(400px);
      animation-timing-function: ease-in;
    }
  }
  .list {
    .text {
      font-size: 16px;
      padding: 5px 0;
    }
    .name {
      font-size: 22px;
      margin: 15px 0 10px;
    }
    img {
      width: 90px;
      height: 90px;
    }
    color: #333333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex {
    align-items: flex-start;
  }
  background: #f0f8ff;
}
.modular-1 {
  .list:hover {
    .bottom {
      background: #166dba;
      transition: all 1s;
      .name,
      .text {
        color: #ffffff !important;
        transition: all 0.5s;
      }
    }
    .el-image {
      transform: scale(1.3);
      transition: all 1.8s;
    }
  }
  .bottom {
    .text {
      font-size: 14px;
      color: #333333;
      width: 293px;
    }
    .name {
      font-size: 20px;
      color: #333333;
      font-weight: bold;
      margin-bottom: 20px;
    }
    background: #f7f8fa;
    width: 100%;
    height: 190px;
    padding: 30px;
  }
  .top {
    .el-image {
      width: 100%;
      height: 100%;
    }
    width: 100%;
    height: 260px;
    overflow: hidden;
  }
  .list {
    width: 350px;
    height: 450px;
    border-radius: 6px;
    overflow: hidden;
  }
}
.modular {
  .title {
    .t3 {
      width: 30px;
      height: 4px;
      background: #cae6ff;
      margin: auto;
    }
    .t2 {
      font-size: 16px;
      color: #dddddd;
      margin: 10px 0 15px;
    }
    .t1 {
      font-size: 24px;
      color: #333333;
      font-weight: bold;
    }
    text-align: center;
    margin-bottom: 40px;
  }
  padding: 50px 0;
  width: 100%;
  overflow: hidden;
  background-position: center center;
}
.banner {
  .view {
    .text {
      font-size: 16px;
      line-height: 27px;
    }
    .henxin {
      width: 70px;
      height: 1px;
      background: rgba(255, 255, 255, 0.8);
      margin: 20px 0;
    }
    .title {
      font-size: 30px;
      font-weight: 500;
    }
    width: 580px;
    color: #ffffff;
    float: right;
  }
  .banner-view {
    position: absolute;
    width: 48%;
    top: 75px;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    padding: 31px 19px;
  }
  .decoration {
    width: 582px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 102%;
    z-index: 10;
    background: url(../../images/home/programme/diao.png) no-repeat;
    background-size: cover;
  }
  width: 100%;
  height: 460px;
  background: url(../../images/home/programme/laowufuwu-banner.png) no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.content {
  width: 1200px;
  margin: auto;
}
.container {
  overflow: hidden;
  width: 100%;
}
</style>
